<template>
	<!-- 报告生成 -->
	<div class="ReportGenerate">
		<div class="ReportGenerate1">
			<el-form label-width="300px" label-position="right">
				<el-form-item label="选择验证模板：">
					<el-select v-model="activityValue" style="flex: 1;" placeholder="请选择验证模板"
						@change="PostVerifyTemplateMap_List(activityValue)">
						<el-option :label="item.T_name" :value="item.T_VerifyTemplate_id"
							v-for="(item,index) in activity" :key="index"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>
		<div v-if="form.length===0" style="display: flex;align-items: center;justify-content: center;padding: 40px;">
			<div style="color: #c4c4c4;">暂无验证模板内容</div>
		</div>
		<div class="ReportGenerate3" v-else>
			<el-form class="forms" v-for="(item,index) in form" :key="index" label-width="300px" label-position="right">
				<el-form-item :label="item.T_name+'：'" v-if="item.T_label===1">
					<div style="display: flex;align-items: center;max-width: 900px;">
						<el-input type="text" v-model="item.T_value"></el-input>
						<el-popover placement="top-start" title="提示" width="200" trigger="hover" :content="item.T_text">
							<i class="el-icon-question" slot="reference"
								style="margin-left: 10px;font-size:16px;color: #c4c4c4"></i>
						</el-popover>
					</div>
				</el-form-item>
				<el-form-item :label="item.T_name+'：'" v-if="item.T_label===2">
					<div style="display: flex;align-items: center;max-width: 700px;">
						<el-input type="number" v-model="item.T_value"></el-input>
						<el-popover placement="top-start" title="提示" trigger="hover" :content="item.T_text">
							<i class="el-icon-question" slot="reference"
								style="margin-left: 10px;font-size:16px;color: #c4c4c4"></i>
						</el-popover>
					</div>
				</el-form-item>

				<el-form-item :label="item.T_name+'(多选)：'" v-if="item.T_label===3">
					<!-- 多选 -->
					<div style="display: flex;align-items: center;max-width: 700px;">
						<el-select v-model="item.T_value" @focus="focuse(item,index)" multiple collapse-tags
							placeholder="请选择">
							<div style="padding: 10px 20px;border-bottom: 1px solid #ccc;text-decoration: underline;cursor: pointer;color: #409EFF;"
								@click="Alls(index)">{{selectAll}}</div>
							<el-option v-for="item1 in selectsData" :key="item1.T_id" :label="item1.T_id"
								:value="item1.T_id">
							</el-option>
						</el-select>
						<el-popover placement="top-start" title="提示" trigger="hover" :content="item.T_text">
							<i class="el-icon-question" slot="reference"
								style="margin-left: 10px;font-size:16px;color: #c4c4c4"></i>
						</el-popover>
					</div>
				</el-form-item>

				<el-form-item :label="item.T_name+'(单选)：'" v-if="item.T_label===4">
					<!-- 单选 -->
					<div style="display: flex;align-items: center;max-width: 700px;">
						<el-select v-model="item.T_value" placeholder="请选择">
							<el-option v-for="item1 in selectsData" :key="item1.T_id" :label="item1.T_id"
								:value="item1.T_id">
							</el-option>
						</el-select>
						<el-popover placement="top-start" title="提示" trigger="hover" :content="item.T_text">
							<i class="el-icon-question" slot="reference"
								style="margin-left: 10px;font-size:16px;color: #c4c4c4"></i>
						</el-popover>
					</div>
				</el-form-item>


				<div style="margin-bottom:100px;" v-if="item.T_label===5">
					<!-- {{item.T_name}} -->
				</div>
				<el-form-item :label="item.T_name+'：'" v-if="item.T_label===9">
					<div style="display: flex;align-items: center">
						<el-date-picker value-format="yyyy-MM-dd HH:mm" v-model="item.T_value" type="datetimerange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
						<el-popover placement="top-start" title="提示" width="200" trigger="hover" :content="item.T_text">
							<i class="el-icon-question" slot="reference"
								style="margin-left: 10px;font-size:16px;color: #c4c4c4"></i>
						</el-popover>
					</div>
				</el-form-item>
			</el-form>
			<el-form label-width="300px" label-position="right"
				style="position: sticky;left: 0;bottom: 0;background: #fff;padding: 20px 0;z-index: 10;box-shadow: 0 -1px 1px 0 #f3f3f3">
				<el-form-item>
					<el-button type="primary" @click="onSubmit">生成报告</el-button>
					<el-button type="danger" @click="onCongzhi">重置数据</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center :show-close="false">
			<el-result icon="success" subTitle="恭喜你,生成报告成功了哦!">
				<template slot="extra">
					<el-button type="primary" size="medium" @click="centerDialogVisible = false">我知道了</el-button>
				</template>
			</el-result>
		</el-dialog>
	</div>
</template>

<script>
	import {
		VerifyTemplateList,
		VerifyTemplateMap_List,
		GenerateReports,
		TaskCheckTaskDataClass,
		GenerateReport_Key
	} from '../../../../api/CorpManage.js'
	export default {
		data() {
			return {
				selectAll: '全选',
				centerDialogVisible: false,
				activity: [],
				activityValue: null,
				form: [],
				boll: true, //boll等于true存在未填写，false全部填写
				selectsData: [],
				ReportLists: this.$store.state.ReportList
			}
		},
		computed: {
			ReportListHC() {
				return this.$store.state.ReportList;
			}
		},
		watch: {
			ReportListHC(newData) {
				this.ReportLists = newData
			}
		},
		mounted() {
			this.PostVerifyTemplateList()
			// console.log('that.selectsData', this.selectsData)
		},
		methods: {
			focuse(s, index) {
				if (this.form[index].T_value.length == this.selectsData.length) {
					this.selectAll = '取消'
				} else {
					this.selectAll = '全选'
				}
			},
			Alls(i) {
				var that = this
				if (this.selectAll === '全选') {
					this.form[i].T_value = []
					that.selectsData.forEach(function(obj) {
						that.form[i].T_value.push(obj.T_id)
					})
					this.selectAll = '取消'
				} else { //全取消
					this.form[i].T_value = []
					this.selectAll = '全选'
				}
			},
			onSubmit() {
				if (this.activityValue === null) {
					this.$message({
						type: 'error',
						message: '请先选择验证模板哦'
					});
				} else {
					var Arr = this.form
					var objs = {}
					var _this = this
					Arr.forEach(function(obj) {
						if (obj.T_label === 3) {
							if (obj.T_value === null) {
								_this.$set(objs, obj.T_field, obj.T_value)
							} else {
								var sel = obj.T_value.join('|')
								_this.$set(objs, obj.T_field, JSON.stringify(sel))
							}
						} else if (obj.T_label === 9) {
							if (obj.T_value === null) {
								_this.$set(objs, obj.T_field, obj.T_value)
							} else {
								_this.$set(objs, obj.T_field, obj.T_value[0] + "|" + obj.T_value[1])
							}
						} else {
							_this.$set(objs, obj.T_field, obj.T_value)
						}
					})
					_this.$set(objs, "Task", _this.$store.state.T_task_id.T_task_id)
					_this.$set(objs, "verify_template_id", _this.verify_template_id)
					// console.log('提交', objs)
					var huancun = {
						T_VerifyTemplate_id: this.activityValue,
						Arrays: this.form
					}
					this.$store.commit('ReportGenData', huancun)
					this.GenerateReportsApi(objs)
				}
			},
			GenerateReportsApi(objs) {
				GenerateReports(objs).then(res => {
					// console.log('提交了', res)
					if (res.data.Code === 200) {
						this.timexxx(res.data.Data,1)
					}
				})
			},
			async timexxx(key,x){
				x = x + 1;
				// if (x > 99999){
				// 	this.$message.error('报告生成失败')
				// 	return
				// }
				let code = await this.GetGeneraKeyApi(key)
				// console.log('await', code)
				if (code.data.Data === 0) {
					this.$message.error('报告生成失败')
				} else if (code.data.Data === 1) {
					this.centerDialogVisible = true
				} else if (code.data.Data === 2) {
					setTimeout(() => {
						this.timexxx(key,x)
					}, 1000)
				} else {
					console.log('111')
					this.$message.error(code.data.Data)
					return
				}
				
			},
			GetGeneraKeyApi(val) {
				return new Promise(resolve => {
					GenerateReport_Key({
						Key: val
					}).then(function(res) {
						resolve(res);
					})
				});
			},
			onCongzhi() {
				this.activityValue = null
				this.$store.commit('ReportGenData', [])
				// window.location.reload()
				this.form = []
			},
			PostVerifyTemplateMap_List(e) { //调用
				var _this = this
				this.verify_template_id = e
				VerifyTemplateMap_List({
					T_VerifyTemplate_id: e
				}).then(res => {
					if (res.data.Code === 200) {
						var Arr = res.data.Data.reverse()
						_this.form = []
						Arr.forEach(function(obj) {
							obj.T_value = null
							if (obj.T_label === 1 || obj.T_label === 2 || obj.T_label === 9 || obj
								.T_label === 3 || obj.T_label === 4 || obj.T_label === 5) {
								_this.form.push(obj)
							}
						})
						_this.PostTaskCheckTaskDataClass()
						_this.ReportLists.forEach(function(obj) { //开始
							if (_this.activityValue === obj.T_VerifyTemplate_id) {
								var RepArr = obj.Arrays
								RepArr.forEach(function(obj1, index) {
									if (obj1.T_field === _this.form[index].T_field) {
										_this.form[index].T_value = obj1.T_value
									}
								})
							}
						})


					}
				})
			},
			PostTaskCheckTaskDataClass() {
				var _this = this
				TaskCheckTaskDataClass({
					T_task_id: _this.$store.state.T_task_id.T_task_id
				}).then(res => {
					if (res.data.Code === 200) {
						_this.selectsData = res.data.Data
					}
				})
			},
			PostVerifyTemplateList() {
				VerifyTemplateList({
					T_name: ''
				}).then(res => {
					if (res.data.Code === 200) {
						this.activity = res.data.Data
					}
				})
			}
		}
	}
</script>

<style>
	@import url("../../../../assets/css/el-dialog.css");

	.ReportGenerate {
		background: #fff;
		padding: 20px;
	}

	.ReportGenerate1 {
		display: flex;
		align-items: center;
	}

	.forms {
		/* display: grid;
		grid-template-columns: 1fr 1fr; */
		/* grid-gap: 0 100px; */
	}
</style>
